import UserProfile from '../../auth0/UserProfile'

export default function AuthScren() {
  return (
    <div>
      <h1>Welcome!</h1>
      <UserProfile />
    </div>
  )
}
