import axios from 'axios'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

export default function VerifyUser() {
  const { user } = useAuth0()
  const navigate = useNavigate()

  const isNewAccount = async () => {
    const response = await axios({
      method: 'GET',
      url: `http://0.0.0.0:8787/getAccount?username=${user.email}`,
    })

    console.log(response)

    if (response?.data?.account === null) {
      navigate('/admin/create-account')
    } else {
      navigate('/home')
    }
  }

  useEffect(() => {
    if (user?.email) {
      isNewAccount()
    }
  }, [user])

  return <div>Verifying...</div>
}
