import { useState } from 'react'
import SelectConnectorsScreen from '../Admin/SelectConnectorsScreen/SelectConnectorsScreen'

export default function HomeScreen() {
  const [data, setData] = useState()
  return (
    <div>
      HomeScreen
      <SelectConnectorsScreen />
    </div>
  )
}
