import React from 'react'
import { createRoot } from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { App } from './App'

const container = document.getElementById('app')
const root = createRoot(container)

root.render(
  <Auth0Provider
    domain="harnonlabs.us.auth0.com"
    clientId="5jpSdaOwEFhVEFSU5iVxI5ZGtaHaBTq1"
    // redirectUri="https://hmnv2-fe.pages.dev/"
    // redirectUri="http://localhost:1234"
    redirectUri="http://localhost:1234/verify"
    useRefreshTokens={true}
    scope="openid profile email read:current_user update:current_user_metadata"
    audience="http://hmn.auth.api"
  >
    <App />
  </Auth0Provider>
)
