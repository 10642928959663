import React from 'react'

export default function SelectConnectorsScreen() {
  const doLogin = async () => {
    // fetch('https://hmnv2-aw-google-ads.harnonlabs.workers.dev/login', {
    //   headers: {
    //     Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjBSclFTd05oNXpFQUltQlViNWlCYSJ9.eyJpc3MiOiJodHRwczovL2hhcm5vbmxhYnMudXMuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDYyMTNkY2QxYzI0MjcyMDA3MDYwZGFlNyIsImF1ZCI6WyJodHRwOi8vaG1uLmF1dGguYXBpIiwiaHR0cHM6Ly9oYXJub25sYWJzLnVzLmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2Njc1Nzc0NDQsImV4cCI6MTY2NzY2Mzg0NCwiYXpwIjoiNWpwU2RhT3dFRmhWRUZTVTVpVnhJNVpHdGFIYUJUcTEiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIHJlYWQ6Y3VycmVudF91c2VyIHVwZGF0ZTpjdXJyZW50X3VzZXJfbWV0YWRhdGEgb2ZmbGluZV9hY2Nlc3MiLCJwZXJtaXNzaW9ucyI6WyJnZXQ6dG9rZW4iLCJyZWFkOmN1cnJlbnRfdXNlciIsInVwZGF0ZTpjdXJyZW50X3VzZXJfbWV0YWRhdGEiXX0.STYzgvunjTpkfLqhY3YIU7c9hCDKEOjp_2TQyYShehrSC91LKbfe_FHJsCivSXiycmyRHtCYiox9n3KbhemNr7rj3x6U-QQ4RL3QPsJiOSBIcvmqQPWHAUH5k0FCdGMUv3fcYOndC9RhMscGDKQfb7X3sSXa1Hn2ustRA5wwt9o-7V74Ce-Pyhp2TOIYOPuAhvUMk_e-HuM8SHnwNW3osLdTxbUGZdtm8Levb7D9r6VRyl-dMDDX6d-mdgia1BerCz0rjdbyfnih5bi0UMR_KJyThC8vfvrLCOHqRackmZaouHw6FTth7E9537oA-Otmh9oXLlGX1Cx3ZUx4yyP4UQ`,
    //   },
    // })
    // let token =
    //   'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjBSclFTd05oNXpFQUltQlViNWlCYSJ9.eyJpc3MiOiJodHRwczovL2hhcm5vbmxhYnMudXMuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDYyMTNkY2QxYzI0MjcyMDA3MDYwZGFlNyIsImF1ZCI6WyJodHRwOi8vaG1uLmF1dGguYXBpIiwiaHR0cHM6Ly9oYXJub25sYWJzLnVzLmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2Njc1OTA4MzUsImV4cCI6MTY2NzY3NzIzNSwiYXpwIjoiNWpwU2RhT3dFRmhWRUZTVTVpVnhJNVpHdGFIYUJUcTEiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIHJlYWQ6Y3VycmVudF91c2VyIHVwZGF0ZTpjdXJyZW50X3VzZXJfbWV0YWRhdGEgb2ZmbGluZV9hY2Nlc3MiLCJwZXJtaXNzaW9ucyI6WyJnZXQ6dG9rZW4iLCJyZWFkOmN1cnJlbnRfdXNlciIsInVwZGF0ZTpjdXJyZW50X3VzZXJfbWV0YWRhdGEiXX0.NMENploc87qW4phA2HguWKimNHJejj3p2S1Itc9YQdVDt9PxNf6zrBTvLocFpI20_fVeOXmFtUG5chLaJcpisS4wpJUuPN6C7f5NEYOGwSyGr65cn8S-m6wGqJ127ZuUaMGy7nqWmKDcE09yrB4kgQD8SZpZz365wvlz4su8w2UbC0SfrlytV276tz2q2Amk9JBcbq6ofIH0YioyE3u-mBnclvf5n4mS2Zv9k02Qt3LphbwH5I3GKv9lbQeIws935M1UV_ZKxk_fVkYqhxgYOpKxN4doHUUVNwKxlu3WL0KRF2JOjxXRyCBsYJ0J57vRpA30Ot3-n8TQcG94mv7cIQ'
    // let xhttp = new XMLHttpRequest()
    // xhttp.onreadystatechange = function () {
    //   if (this.readyState == 4 && this.status == 200) {
    //     // Typical action to be performed when the document is ready:
    //     // document.getElementById('demo').innerHTML = xhttp.responseText
    //     console.log(xhttp.responseText)
    //   }
    // }
    // xhttp.open(
    //   'GET',
    //   'https://hmnv2-aw-google-ads.harnonlabs.workers.dev/login',
    //   true
    // )
    // xhttp.setRequestHeader('Authorization', `Bearer ${token}`)
    // xhttp.send()
    // window.location.replace(
    //   'https://hmnv2-aw-google-ads.harnonlabs.workers.dev/login'
    // )
  }
  return (
    <div>
      <h1>Select your connectors:</h1>
      <ul>
        <li>
          <a href="https://hmnv2-lw-googleads.harnonlabs.workers.dev/login">
            GoogleAds
          </a>
        </li>
        <li>Google Analytics</li>
        <li>Google Console</li>
        <li>Hubspot</li>
        <li>Marketo</li>
      </ul>
    </div>
  )
}
