import React from 'react'
import LoginButton from './auth0/LoginBtn'
import LogoutButton from './auth0/LogoutButton'
import UserProfile from './auth0/UserProfile'
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom'
import Root from './routes/root'
import VerifyUser from './components/admin/VerifyUser/VerifyUser'
import CreateAccountScreen from './screens/Admin/CreateAccount/CreateAccount'
import HomeScreen from './screens/HomeScreen/HomeScreen'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: '/verify',
    element: <VerifyUser />,
  },
  {
    path: '/admin/create-account',
    element: <CreateAccountScreen />,
  },
  {
    path: '/home',
    element: <HomeScreen />,
  },
])

const defaultContext = {}
export const AppContext = React.createContext(defaultContext)

export function App() {
  return (
    <AppContext.Provider value={defaultContext}>
      <LoginButton />
      <LogoutButton />
      <RouterProvider router={router} />
    </AppContext.Provider>
  )
}
