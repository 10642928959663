import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import NonAuthScreen from '../screens/NonAuthScreen/NonAuthScreen'
import AuthScreen from '../screens/AuthScreen/AuthScreen'

export default function Root() {
  const { token, setToken } = React.useState()
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()

  React.useEffect(() => {
    async function callAccessToken() {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `http://hmn.auth.api`,
          scope: 'read:current_user',
        })

        console.log(accessToken)
        setToken(accessToken)
      } catch (e) {
        console.log(e.message)
      }
    }
    callAccessToken()
  }, [getAccessTokenSilently])

  return <div>{isAuthenticated ? <AuthScreen /> : <NonAuthScreen />}</div>
}
