import axios from 'axios'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import SelectConnectorsScreen from '../SelectConnectorsScreen/SelectConnectorsScreen'

export default function CreateAccountScreen() {
  const { user } = useAuth0()

  const [company, setCompany] = useState('')
  const [showConnectorsScreen, setShowConnectorsScreen] = useState(false)

  const createAccount = async () => {
    if (user?.email) {
      const response = await axios({
        method: 'PUT',
        url: `http://0.0.0.0:8787/createAccount`,
        data: {
          id: user.sub,
          username: user.email,
          company,
        },
      })
      if (response?.data?.message === 'Created' && response.status === 200) {
        console.log('Select your connectors')
        setShowConnectorsScreen(true)
      }
    }
  }

  return (
    <div>
      <h1>Create Account</h1>
      <label htmlFor="company">
        Your Company:
        <input
          type="text"
          name="company"
          id="company"
          onChange={(e) => setCompany(e.target.value)}
        />
      </label>
      <button onClick={createAccount}>Next</button>
      {showConnectorsScreen && <SelectConnectorsScreen />}
    </div>
  )
}
